import React, { useState } from 'react';
import Header from './components/Header';
import Card from './components/Card';
import Footer from './components/Footer';
import Searchfrom from './components/Searchform';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
// import { BrowserRouter } from 'react-router-dom
import AllAiTools from './components/AllAiTools';
import News from './components/News';
import Jobs from './components/Jobs';

function App() {
 

  return (
    <>
    
    <Router>
      <Header />
      {/* <AllAiTools/> */}
    

      <Routes>
        {/* <Route path='/'} element={<Main/>}/> */}
    
        <Route path='/' element={<Card/>} />
        <Route path='/AllAiTools' element={<AllAiTools />} />
        <Route path='/news' element={<News />} />
        <Route path='/Jobs' element={<Jobs />} />
      </Routes>
      <Footer />
    </Router>
    
    </>
  );
}

export default App;

