
export const CardContent = [
    {
        image:"./images/chatgpt.png",
        name:"ChatGPT",
        tag:"Chat Assistant",
        description:"ChatGPT improves language models for conversations, enhancing the quality and relevance of your chatbot's replies.",
        MyLink:"https://chat.openai.com/",
        id:1
    },
    {
        image:"./images/gemini.png",
        name:"Gemini",
        tag:"Chat Assistant",
        description:" Google gemini supercharge your creativity and productivity- Chat to start writing, planning, learning and more.",
        MyLink:"https://gemini.google.com/",
        id:2
    },
    {
        image:"./images/dalle.png",
        name:"DALL-E 2",
        tag:"images",
        description:"A revolutionary AI system that can generate realistic images and art from a simple text description.",
        MyLink:"https://openai.com/dall-e-2",
        id:3
    },
    {
        image:"./images/jasper.png",
        name:"Jasper AI",
        tag:"Writing assistant",
        description:"An AI writing assistant that excels in creating different kinds of marketing copy, blog posts, and even creative text formats like poems and code.",
        MyLink:"https://www.jasper.ai/",
        id:4
    },
    {
        image:"./images/midjourney.png",
        name:"Midjourney",
        tag:"text to images",
        description:"Midjourney is an AI art generator that transforms your text descriptions into stunning and unique images.",
        MyLink:"https://www.midjourney.com/home",
        id:5
    },
    {
        image:"./images/Synthesia.png",
        name:"Synthesia",
        tag:"text to videos",
        description:"Synthesia turns text into professional-looking videos with AI presenters. No cameras or filming required.",
        MyLink:"https://www.synthesia.io/",
        id:6
    },
    {
        image:"./images/tome.png",
        name:"Tome",
        tag:"Presentation",
        description:"AI-powered tool for generating comprehensive presentations, reports, and storytelling formats.",
        MyLink:"https://tome.app/",
        id:7
    },
    {
        image:"./images/namelix.png",
        name:"namelix",
        tag:"Business",
        description:"AI-powered business name generator that creates short, brandable, and memorable names.",
        MyLink:"https://namelix.com/?utm_source=futurepedia&utm_medium=marketplace&utm_campaign=futurepedia#",
        id:8
    },


]