import React from "react";

const News = () => {
  return (
    <>
      <div className="flex flex-wrap justify-center mt-28 font-semibold text-3xl lg:text-6xl leading-10">
        <div>Latest AI news and updates</div>
      </div>
      <p className="flex justify-center mt-2 font-mono leading-10">
        Latest AI news here
      </p>
      <div className="flex justify-center">
        <div className="flex items-center flex-wrap p-6 m-7 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="w-full">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Montreal AI Analytics Startup Rithmik Raises $2M Funding Round AI
              Analytics Startup Rithmik Raises $2M Funding Round.
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              📰 News
            </p>
          </div>
          <div>
            <a
              href="https://www.thesaasnews.com/news/growth-round"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Read More...
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>

        <div className="flex items-center flex-wrap p-6 m-7 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="w-full">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Montreal AI Analytics Startup Rithmik Raises $2M Funding Round AI
              Analytics Startup Rithmik Raises $2M Funding Round.
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              📰 News
            </p>
          </div>
          <div>
            <a
              href="https://www.thesaasnews.com/news/growth-round"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Read More...
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
