// import React, { useState } from "react";
const Searchfrom = ({ setData }) => {
  const handleChange = (e) => {
    setData(e.target.value);
  };

  return (
    <>
      <div className="flex justify-center mt-28 font-bold text-4xl lg:text-6xl leading-10">
        Explore the World of AI Tools
      </div>
      <p className="flex justify-center mt-2 font-bold leading-10">Search AI Tools for your work- like Code, Video... </p>

      <form className="max-w-md mx-auto mt-3">
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
          <input
            type="search"
            id="default-search"
            onChange={handleChange}
            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search AI Tools Videos, Code..."
            required
          />
        </div>
      </form>
    </>
  );
};

export default Searchfrom;
