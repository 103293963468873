import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="fixed top-0 left-0 w-full z-50">
      <div className="text-center bg-[rgb(121,239,255)] h-[30px]">
        <h2 className="font-semibold">
          Latest AI tools updated everyday{" "}
          <a href="#" className="underline hover:text-white italic">
            Join Our Social media Community↗️
          </a>
        </h2>
      </div>

      <header className="bg-[#f8f7f7] py-3 font-bold shadow-md">
        <div className="container mx-auto px-4 md:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center h-4">
              <Link to="/" >
              <img
                className="h-56 "
                src="./assets/images/logo03.png"
                alt="Logo"
              />
              </Link>
            </div>
            <div className="middle-navbar flex items-center space-x-6 lg:space-x-10">
            <button>
                <Link to="/" className="hover:underline">
                🏠 Home
                </Link>
              </button>
              <button>
                <Link to="/News" className="hover:underline">
                📰 News
                </Link>
              </button>
              <button>
                <Link to="/Jobs" className="hover:underline">
                💼 Jobs
                </Link>
              </button>
              <button>
                <Link to="/AllAiTools" className="hover:underline">
                🤖 AI Tools
                </Link>
              </button>
            </div>
            <div className="right-navbar hidden lg:flex items-center space-x-2">
              <button className="px-4 rounded-lg hover:underline">
              ✉️ Contact us
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
