import React, { useState } from "react";
import { CardContent } from "../content/CardContent";

const AllAiTools = ({ data }) => {
  // const [displayedCards, setDisplayedCards] = useState();

  // const handleShowMore = () => {
  //   setDisplayedCards((prevDisplayedCards) => prevDisplayedCards + 4);
  // };

  // const filteredData = CardContent.filter((da) =>
  //   da.tag.toLowerCase().includes(data)
  // );

  return (
    <div>
    <div className="flex justify-center mt-28 font-bold text-3xl lg:text-6xl leading-10">
    All AI Tool of the World.
      </div>
      <p className="flex justify-center mt-2 font-mono leading-10">Find AI Tools for your work... </p>
      <div className="flex flex-wrap justify-center mt-[11px]">
        {CardContent.map((item) => (
          <div
            className="bg-white w-[20%] m-[11px] lg:p-5 hover:shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg shadow-[rgba(0,_0,_0,_0.1)_0px_3px_8px] "
            key={item.id}
          >
            <img
              className="rounded-xl h-[150px] w-full"
              src={item.image}
              alt="img"
            />
            <div>
              <h2 className="font-bold text-xl">{item.name}</h2>
              <p className="font-semibold">→ {item.tag}</p>
              <div className="flex flex-row">
                <p>{item.description}</p>
              </div>
              <div className="flex flex-row items-center justify-center mt-3">
                <a
                  href={item.MyLink}
                  className=" flex justify-center font-bold p-1 w-full rounded shadow-sm shadow-slate-300 hover:shadow-green-500 transition duration-300"
                >
                  Visit
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* {displayedCards < filteredData.length && (
        <div className="flex justify-center mt-4">
          <button
            className="flex justify-center font-bold p-3 rounded shadow-[rgba(0,_0,_1,_1)_0px_3px_8px] hover:shadow-[rgba(0,_0,_0,_1)_0px_2px_10px]"
                
            onClick={handleShowMore}
          >
            Show More AI Tools
          </button>
        </div> 
      )} */}
    </div>
  );
};

export default AllAiTools;