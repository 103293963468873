import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900 mt-4">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex justify-between">
          <div className="flex-1 mb-4 md:mb-0"> {/* Added flex-1 class */}
            <div href="#" className="flex items-center">
              <img
                src="./assets/images/logo02.png"
                className="h-8 me-3"
                alt="Logo"
              />
              <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Explore AI Hub</span>
            </div><p className="text-sm m-4 dark:text-white">We're a team of AI enthusiasts dedicated to making AI accessible for everyone. Join our growing community</p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div className="flex-1"> {/* Added flex-1 class */}
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Company</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="https://www.google.co.in" className="hover:underline">Contact us</a>
                </li>
                <li>
                  <a href="#" className="hover:underline">Submit AI Tool</a>
                </li>
              </ul>
            </div>
            <div className="flex-1">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:underline ">Instagram</a>
                </li>
                <li>
                  <a href="#" className="hover:underline">Telegram</a>
                </li>
              </ul>
            </div>
            <div className="flex-1"> {/* Added flex-1 class */}
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:underline">Privacy Policy</a>
                </li>
                {/* <li>
                  <a href="#" className="hover:underline">Terms &amp; Conditions</a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;