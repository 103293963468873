import React, { useState } from "react";
import { CardContent } from "../content/CardContent";
import Searchfrom from "./Searchform";

const Card = () => {
  const [displayedCards, setDisplayedCards] = useState(8);
  const [data, setData] = useState("")

  const handleShowMore = () => {
    setDisplayedCards((prevDisplayedCards) => prevDisplayedCards + 4);
  };

  const filteredData = CardContent.filter((da) =>
  da.tag.toLowerCase().includes(data) || da.name.toLowerCase().includes(data)
);

  return (
    <div>
    <div>
    <Searchfrom setData={setData}/>
    </div>
      <div className="flex flex-wrap justify-center">
        {filteredData.slice(0, displayedCards).map((item) => (
          <div
            className="bg-white w-[20%] m-[11px] lg:p-5 hover:shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg shadow-[rgba(0,_0,_0,_0.1)_0px_3px_8px] "
            key={item.id}
          >
            <img
              className="rounded-xl h-[150px] w-full"
              src={item.image}
              alt="img"
            />
            <div>
              <h2 className="font-bold text-xl">{item.name}</h2>
              <p className="font-semibold">→ {item.tag}</p>
              <div className="flex flex-row">
                <p>{item.description}</p>
              </div>
              <div className="flex flex-row items-center justify-center mt-3">
                <a
                  href={item.MyLink}
                  className=" flex justify-center font-bold p-1 w-full rounded shadow-sm shadow-slate-300 hover:shadow-green-500 transition duration-300"
                >
                  Visit
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      {displayedCards < filteredData.length && (
        <div className="flex justify-center mt-4">
          <button
            className="flex justify-center font-bold p-3 rounded shadow-[rgba(0,_0,_1,_1)_0px_3px_8px] hover:shadow-[rgba(0,_0,_0,_1)_0px_2px_10px]"
                
            onClick={handleShowMore}
          >
            Show More AI Tools
          </button>
        </div>
      )}
    </div>
  );
};

export default Card;

// Main code-
// import React from "react";
// import { CardContent } from "../content/CardContent";

// const Card = ({data}) => {
// console.log(data, "card data")
//   return (
//     <div className="flex flex-wrap justify-center">
//       {CardContent.filter((da)=>da.tag.toLowerCase().includes(data)).map((item)=>{
//         item.tag.toLowerCase();
//         return(

//           <div
//           className="bg-yellow-300 w-[20%] m-[11px] lg:p-5 shadow-[rgba(0,_0,_0,_0.5)_0px_3px_8px] rounded-lg"
//           key={item.id}
//         >
//           <img className="rounded-xl h-56 w-full" src={item.image} alt="img" />
//           <div className="space-y-4">
//             <h3 className="font-semibold text-center text-xl pt-6">
//               {item.name}
//             </h3>
//             <p className="font-semibold text-center text-xl pt-6">
//               {item.tag}
//             </p>
//             <div className="flex flex-row justify-center">
//               <p>
//                 {item.description}{" "}
//               </p>
//             </div>
//             <div className="flex flex-row items-center justify-center gap-4">
//               <a href={item.MyLink}
//                 className="font-bold p-2 rounded bg-blue-400 hover:bg-blue-700 text-white transition duration-300">
//                 visit
//               </a>
//             </div>
//           </div>
//         </div>
//         )
//       })}
//     </div>
//   );
// };

// export default Card;
